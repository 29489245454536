// 2019-11-09 21:31:38
import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-auto-rows: minmax(200px, auto);
  & > * {
    padding: 1em;
    border: solid green;
  }
`;

const Child1 = styled.div`
  grid-column: 1/3;
  grid-row: 1;
`;
const Child2 = styled.div`
  grid-column: 3;
  grid-row: 1/3;
`;
const Child3 = styled.div``;
const Child4 = styled.div`
  grid-column: 2;
  grid-row: 2/4;
`;
const Child5 = styled.div``;

const Stowaway = () => {
  return (
    <Layout>
      <SEO title="CSS Grid"></SEO>
      <p>
        <Wrapper>
          <Child1>Child 1</Child1>
          <Child2>Child 2</Child2>
          <Child3>Child 3</Child3>
          <Child4>Child 4</Child4>
          <Child5>Child 5</Child5>
        </Wrapper>
      </p>
    </Layout>
  );
};

export default Stowaway;
